import React from 'react';


function Universities() {
  return (
    <>


      <div class="bbbootstrap">
        <div class="container">
          <div className='row text-light text-center'>
            <div className='col-md-2'>
              <img src="https://www.standyou.com/uploads/20200213105035Ivane-Javakhishvili-Tbilisi-State-University-Georgia.png" alt="" width={180} />
            </div>
            <div className='col-md-10'>
              <h1 className='text-light text-center'>Ivane Javakhishvili Tbilisi State University</h1>
              <div className='col-sm-12 d-flex'>


                <div className='col-sm-6 lists'>
                  <ul >
                    <li className='lists'>Rating - 4.8 Points</li>
                    <li className='lists'>Rating - 4.8 Points</li>
                    <li className='lists'>Rating - 4.8 Points</li>
                    <li className='lists'>Rating - 4.8 Points</li>

                  </ul>
                </div>
                <div className='col-sm-6 lists'>
                  <ul >
                    <li className='lists'>Rating - 4.8 Points</li>
                    <li className='lists'>Rating - 4.8 Points</li>
                    <li className='lists'>Rating - 4.8 Points</li>
                    <li className='lists'>Rating - 4.8 Points</li>

                  </ul>
                </div>

              </div>

            </div>






          </div>
        </div>
      </div>




    </>
  )
}

export default Universities