import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
// import "./Coaching.css";

function Coaching() {
    return (
        <>
        <Navbar/>
         
            <Footer/>
        </>
        
    )
}

export default Coaching