import React from 'react';
import "./timeline.css";
import uk from "../images/uk.png";
import aus from "../images/Australia-flag.png";
import cana from "../images/Canada-flag.png";
import china from "../images/china-flag.png";
import france from "../images/France-flag.png";
import germany from "../images/Germany-flag.png"
import india from "../images/India-flag.png";
import ireland from "../images/Ireland-flag.png";
import italy from "../images/Italy-flag.png";
import newz from "../images/New Zealand-flag.png";
import singapor from "../images/Singapore-flag.png";
import koria from "../images/South Korea-flag.png";
import spain from "../images/Spain-flag.png";
import swit from "../images/Switzerland-flag.png";
import us from "../images/US-flag.png";
import "../pages/home.css";



const Timeline = () => {


    return (
        <>
            <div className="container-fluid" style={{ backgroundImage: "url(' https://leverageedu.s3.ap-south-1.amazonaws.com/about-us/about-us-bg-web.png')", backgroundRepeat: "no-repeat", backgroundSize: "", height: "auto" }}>
                <div className="container p-5 timeline">
                    <div className="row mb-5" >
                        <h1 className="fw-bold text-center mt-5"> <span style={{ color: "#a8203b" }}> Our</span> Students</h1>
                        <h6 className="text-center mb-5">Across the globe</h6>

                        <div className="col-sm-5 text-center left-image-div">
                            <img src="https://images.leverageedu.com/assets/img/about-us/mirage-teamwork.png" className="" height={300} alt="" />
                        </div>
                        <div className="col-sm-7 right-content-div">
                            <div className=" " style={{ display: "inline-flex", flexWrap: "wrap" }}>
                                <div className=" text-center shadow m-2 bg-light p-2">
                                    <img src={uk} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>Uk</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>
                                <div className=" text-center shadow  m-2 bg-light p-2">
                                    <img src={aus} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>Australia</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>
                                <div className=" text-center shadow  m-2 bg-light p-2">
                                    <img src={cana} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>Canada</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>
                                <div className=" text-center shadow  m-2 bg-light p-2">
                                    <img src={china} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>china</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>
                                <div className=" text-center shadow  m-2 bg-light p-2">
                                    <img src={france} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>France</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>
                                <div className="text-center shadow  m-2 bg-light p-2">
                                    <img src={germany} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>Germany</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>
                                <div className=" text-center shadow  m-2 bg-light p-2">
                                    <img src={india} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>India</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>
                                <div className=" text-center shadow  m-2 bg-light p-2">
                                    <img src={ireland} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>Ireland</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>
                                <div className=" text-center shadow  m-2 bg-light p-2">
                                    <img src={italy} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>Italy</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>
                                <div className=" text-center shadow  m-2 bg-light p-2">
                                    <img src={newz} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>NewZealand</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>
                                <div className=" text-center shadow  m-2 bg-light p-2">
                                    <img src={singapor} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>Singapore</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>
                                <div className=" text-center shadow  m-2 bg-light p-2">
                                    <img src={koria} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>South Korea</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>
                                <div className=" text-center shadow  m-2 bg-light p-2">
                                    <img src={spain} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>Spain</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>
                                <div className=" text-center shadow  m-2 bg-light p-2">
                                    <img src={swit} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>Switzerland</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>
                                <div className="text-center shadow  m-2 bg-light p-2">
                                    <img src={us} alt="" />
                                    <p className="mb-0" style={{ fontSize: "12px", borderRadius: "20px" }}>Usa</p>
                                    <p style={{ fontSize: "12px" }}>7000+ Offers</p>
                                </div>




                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Timeline